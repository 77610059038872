import React, { useState } from "react"

const DataProvider =
  (ConnectedComponent, language) =>
  ({ data }) => {
    const formattedData = {
      campaigns: data.allCampaign.edges.map(edge => edge.node),
    }

    const formattedProps = {
      allCampaigns: formattedData.campaigns,
      featuredCampaigns: formattedData.campaigns.filter(
        camp => camp.acf.featured && !camp.acf.archive
      ),
      autoSuggestData: formattedData.campaigns.map(c => ({
        title: c.title.rendered,
        slug: c.slug,
      })),
    }
    return <ConnectedComponent {...formattedProps} />
  }

export default DataProvider
