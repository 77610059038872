/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react"
import he from "he"
import CookieConsent from "react-cookie-consent"

import { Link } from "react-scroll"

import { Button, Autosuggest, Card } from "../../components/components"
import styles from "./_styles.module.scss"

import handsUp from "../../../static/images/hero-handsUp-min.png"
import protest from "../../../static/images/protest-min.webp"
import about from "../../../static/images/about.webp"


const DEFAULT_CAMPAIGN_LIMIT = 6;

const PageComponent = ({
  copy,
  featuredCampaigns,
  allCampaigns,
  autoSuggestData,
  pageMounted,
}) => {
  const [pageSize, addPageSize] = useState(DEFAULT_CAMPAIGN_LIMIT);
  const cookieConfiguration = {
    location: "top",
    buttonText: copy.cookies.acceptButton,
    cookieName: "allow-cookies",
    style: { background: "#2B373B" },
    buttonStyle: { color: "#4e503b", fontSize: "13px" },
    expires: 150,
  }


  useEffect(() => {
    window.location.href = 'https://campax.org';
  }, []);

  return (
    <>
      <CookieConsent {...cookieConfiguration}>
        {copy.cookies.body}
        <a href={copy.cookies.bodyMoreInfoUrl}>
          {copy.cookies.bodyMoreInfoUrl}
        </a>
      </CookieConsent>
      <div className={styles.hero}>
        <img className={styles.heroBackground} src={handsUp} alt="" />
        <div className={styles.searchBoxWrapper}>
          <h1>{copy.homepage["heroTitle-0"]}</h1>
          <p>{copy.homepage.heroSubtitle}</p>
          {autoSuggestData.length == 0 ? (
            <div className={styles.noCampaignsMessage}>
              {copy.homepage.noActiveCampaigns}
            </div>
          ) : (
            <>
              <Autosuggest
                data={autoSuggestData}
                placeholder={copy.homepage.searchPlaceholder}
              />
              <div className={styles.heroButtonsGroup}>
                <Link
                  activeClass="active"
                  spy
                  smooth
                  offset={-60}
                  duration={500}
                  to="latestCampaignsBlock"
                >
                  <Button
                    type="primary"
                    label={copy.homepage.heroCallToAction}
                  />
                </Link>
                <Link
                  activeClass="active"
                  spy
                  smooth
                  offset={-60}
                  duration={500}
                  to="showAllBlock"
                >
                  <Button type="secondary" label={copy.homepage.heroShowAll} />
                </Link>
              </div>
            </>
          )}
        </div>
      </div>

      <div className={styles.featuredCampaignsBox} id="latestCampaignsBlock">
        <h1>{copy.homepage.featuredCampaignsTitle}</h1>
        <div className={styles.campaignCardsWrapper}>
          {featuredCampaigns.length == 0 || !pageMounted ? (
            <div className={styles.noCampaigns}>
              {copy.homepage.noActiveCampaigns}
            </div>
          ) : (
            featuredCampaigns.map(campaign => (
              <Card
                key={Math.random()}
                title={he.decode(campaign.title.rendered)}
                cover={campaign.acf.cover_image}
                description={campaign.acf.cover_description}
                slug={campaign.slug}
                buttonLabel={copy.homepage.checkOutCampaign}
              />
            ))
          )}
        </div>
      </div>

      <div className={styles.latestCampaignsBox} id="showAllBlock">
        <h1>{copy.homepage.allCampaignsTitle}</h1>
        <div className={styles.campaignCardsWrapper}>
          {allCampaigns.length == 0 || !pageMounted ? (
            <div className={styles.noCampaigns}>
              {copy.homepage.noActiveCampaigns}
            </div>
          ) : (
            <>
            {allCampaigns.map((campaign, index) => index < pageSize ? (
              <Card
                key={Math.random()}
                title={he.decode(campaign.title.rendered)}
                cover={campaign.acf.cover_image}
                description={campaign.acf.cover_description}
                slug={campaign.slug}
                buttonLabel={copy.homepage.checkOutCampaign}
              />
            ) : (<></>))}
            </>
          )}
        </div>
        {allCampaigns.length > (pageSize - 1) && (
          <div className={styles.showMoreWrapper}>
        <Button onClick={() => addPageSize(pageSize + 5)} type="secondary" label={copy.homepage.showMoreButton} />
        </div>
        )}
      </div>
      <div className={styles.aboutBox} id="about">
        <img className={styles.aboutPoster} src={about} alt="Poster" />
        <div className={styles.aboutContent}>
          <h1>{copy.homepage.aboutTitle}</h1>
          <p>{copy.homepage.aboutBlock}</p>
        </div>
      </div>
      <div className={styles.whySignBox} id="collect">
        <img
          className={styles.protestPoster}
          src={protest}
          alt="Climate Change Poster"
        />
        <div className={styles.whySignContent}>
          <h1>{copy.homepage.whySignTitle}</h1>
          <p>{copy.homepage["whySignText-0"]}</p>
          <p>{copy.homepage["whySignText-1"]}</p>
        </div>
      </div>
    </>
  )
}

PageComponent.propTypes = {}

PageComponent.defaultProps = {}

export default PageComponent