import React from "react"

import { Header, Footer } from "../../components/components"

import styles from "./staticLayout.module.scss"

const StaticLayout = ({
  children,
  header,
  footerSocial,
  footerVersion,
  copyrights,
  de,
  fr,
  it,
  privacy,
  impressum,
  privacyLink,
  impressumLink,
}) => (
  <div className={styles.layoutWrapper}>
    <Header
      type="landingPage"
      headerCopy={header}
      defaultLanguage={header.defaultLanguage}
      firstLanguage={header.firstLanguage}
      secondLanguage={header.secondLanguage}
    />
    <main className={styles.mainLayout}>{children}</main>
    <Footer
      copyrights={copyrights}
      footerSocial={footerSocial}
      footerVersion={footerVersion}
      de={de}
      fr={fr}
      it={it}
      privacy={privacy}
      impressum={impressum}
      impressumLink={impressumLink}
      privacyLink={privacyLink}
    />
  </div>
)

export default StaticLayout
